<template>
  <div ref="ticketOrder" class="ticket-order">
    <div ref="functionButtons" class="function-buttons"></div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="form"
        label-position="left"
        label-width="85px"
        class="demo-form-inline"
      >
        <el-form-item label="订单编号：">
          <el-input
            v-model.trim="form.orderId"
            placeholder="请输入订单编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="下单人：">
          <el-input
            v-model.trim="form.passengerPhone"
            placeholder="请输入下单人"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话：">
          <el-input
            v-model.trim="form.orderPhone"
            placeholder="请输入联系人电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单状态：">
          <el-select
            v-model="form.orderStatus"
            collapse-tags
            multiple
            clearable
            placeholder="请选择订单状态"
          >
            <el-option
              v-for="role in orderStatusList"
              :key="role.value"
              :label="role.label"
              :value="role.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单时间起：">
          <el-date-picker
            v-model="form.startDate"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="下单时间止：">
          <el-date-picker
            v-model="form.endDate"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            default-time="23:59:59"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="支付时间起：">
          <el-date-picker
            v-model="form.paymentStartDate"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="支付时间止：">
          <el-date-picker
            v-model="form.paymentEndDate"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            default-time="23:59:59"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="发车时间起：">
          <el-date-picker
            v-model="form.departureStartDateTime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="发车时间止：">
          <el-date-picker
            v-model="form.departureEndDateTime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            default-time="23:59:59"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="线路：">
          <el-select v-model="form.lineId" clearable placeholder="请选择线路">
            <el-option
              v-for="role in lineList"
              :key="role.id"
              :label="role.name"
              :value="role.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务类型：">
          <el-select
            v-model="form.businessType"
            clearable
            placeholder="请选择业务类型"
          >
            <el-option label="城际专车" :value="1"></el-option>
            <!-- <el-option label="汽车订票" :value="1"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="购票方式：">
          <el-select
            v-model="form.purchaseMethod"
            clearable
            placeholder="请选择购票方式"
          >
            <el-option
              v-for="role in payMethodList"
              :key="role.value"
              :label="role.label"
              :value="role.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="small"
            :loading="searchLoading"
            @click="onSearch()"
            >查询</el-button
          >
          <el-button
            v-if="listFind('导出')"
            type="primary"
            size="small"
            @click="exportFun"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :table-height="tableHeight"
      :operation="true"
      operation-width="120"
    >
      <template slot-scope="scope">
        <el-button
          type="text"
          size="small"
          @click="openPopupFun(scope.scopeRow)"
          >查看</el-button
        >
        <el-button
          v-if="listFind('优惠信息')"
          type="text"
          size="small"
          @click="showCouponsFun(scope.scopeRow)"
          >优惠信息</el-button
        >
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
      <!-- 表格详情弹框 -->
    </div>
    <div v-if="tablePopupShow">
      <table-popup
        :table-data2="tableData2"
        :title-name2="titleName2"
        :data-popup="dataPopup"
        :order-status-list="orderStatusList"
        @closeFun="closeFun"
      />
    </div>
    <!-- 优惠券详情 -->
    <div v-if="couponsPopupShow">
      <coupons-popup
        :data-popup="couponsDataPopup"
        :scope-list="scopeList"
        :coupons-list="couponsList"
        @closeCouponsFun="
          () => {
            this.couponsPopupShow = false;
          }
        "
      />
    </div>
  </div>
</template>

<script>
import {
  queryScheduleHistoryList,
  getAllLine,
  historyExportAPI,
  referOrderDetialAPI,
  referOrderCouponsDetailAPI, //获取主订单优惠流水详情
} from "@/api/lib/api.js";
import tablePopup from "@/components/charterPopup/tablePopup.vue";
import couponsPopup from "@/components/charterPopup/couponsDetail.vue"; //主订单优惠流水详情
export default {
  components: { tablePopup, couponsPopup },
  name: "ticketOrder",
  data() {
    return {
      titleName: [
        {
          title: "订单编号",
          props: "orderId",
          width: "200",
        },
        {
          title: "下单人",
          props: "passengerPhone",
        },
        {
          title: "联系电话",
          width: 120,
          props: "orderPhone",
        },
        {
          title: "线路",
          width: 100,
          SpecialJudgment: function (res) {
            return res.startStation + `-` + res.endStation;
          },
        },
        {
          title: "班次时间",
          width: 190,
          SpecialJudgment: (res) => {
            let timeStr = res.startDepartureTime;
            if(res.shiftType === 1) {
              timeStr += '-' + res.endDepartureTime;
            }
            return res.departureDate + ` ` + timeStr;
          },
        },
        {
          title: "有效乘车人数",
          width: 120,
          props: "validPassengersNum",
        },
        {
          title: "乘客数量",
          props: "passengerCount",
        },
        {
          title: "携童数",
          props: "carryingChildrenNum",
        },
        {
          title: "票价",
          props: "ticketPricesPer",
        },
        {
          title: "服务费",
          props: "servicePricesPer",
        },
        {
          title: "应付金额",
          props: "payablePrices",
        },
        {
          title: "优惠金额",
          props: "preferentialPrices",
        },
        {
          title: "实付金额",
          props: "totalPrices",
        },
        {
          title: "购票方式",
          props: "purchaseMethod",
          SpecialJudgment: (res) =>
            this.payMethodList.find((e) => e.value == res).label,
        },
        {
          title: "上车点",
          props: "startAddress",
        },
        {
          title: "下车点",
          props: "endAddress",
        },
        {
          title: "下单时间",
          width: 170,
          props: "createTime",
        },
        {
          title: "支付时间",
          width: 170,
          props: "wxTransactionTime",
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            return this.orderStatusList.find((t) => t.value == res).label;
          },
        },
        {
          title: "退(改)优惠金额",
          props: "refundTicketPreferentialPrices",
          width: 130,
        },
        {
          title: "退票金额",
          props: "refundFee",
        },
        {
          title: "退票手续费",
          width: "100",
          props: "poundage",
        },
        {
          title: "最后退票时间",
          props: "refundTime",
          width: 110,
        },
        {
          title: "改签退回金额",
          props: "changedRefundPrices",
          width: 120,
        },
        {
          title: "改签手续费",
          props: "changedServicePrices",
          width: 100,
        },
        {
          title: "最后改签时间",
          props: "changedTime",
          width: 120,
        },
      ],
      tableHeight: 0,
      tableData: [],
      form: {
        orderId: null,
        passengerPhone: null,
        orderPone: null,
        orderStatus: null,
        lineId: null,
        startDate: "",
        endDate: "",
        businessType: null,
        currentPage: 1,
        pageSize: 10,
        departureStartDateTime: null,
        departureEndDateTime: null,
        purchaseMethod: null,
        paymentEndDate: null,
        paymentStartDate: null,
      },
      total: 0,
      lineList: [],
      orderStatusList: [
        { value: 10, label: "待支付" },
        { value: 20, label: "待配车" },
        { value: 30, label: "已配车（待派单）" },
        { value: 40, label: "已派单（待接驾）" },
        { value: 50, label: "司机申请取消接驾" },
        { value: 60, label: "已接驾" },
        { value: 70, label: "已完成" },
        { value: 80, label: "取消接驾" },
        { value: 110, label: "订单取消" },
        { value: 120, label: "已退票" },
        { value: 130, label: "已改签" },
      ],
      refundStatusList: [
        { value: "SUCCESS", label: "退款成功" },
        { value: "CHANGE", label: "退款异常" },
        { value: "REFUNDCLOSE", label: "退款关闭" },
        { value: "REFUNDING", label: "退款中" },
        { value: "NOTPAY", label: "未支付" },
      ],
      childOrderStatusList: [
        { value: 20, label: "已支付" },
        { value: 90, label: "用户已退票" },
        { value: 95, label: "退款中" },
        { value: 100, label: "后台已退票" },
        { value: 130, label: "已改签" },
        { value: null, label: "" },
      ],
      payMethodList: [
        { value: 0, label: "乘客购票" },
        { value: 2, label: "快客码购票" },
        { value: 1, label: "后台购票" },
        { value: 3, label: "API购票" },
        { value: 4, label: "预留" },
      ],
      tableData2: [],
      titleName2: [
        {
          title: "票号",
          props: "id",
          width: "200",
        },
        {
          title: "乘客类型",
          props: "segment",
          SpecialJudgment: (res) => {
            return res == 0 ? "儿童" : "成人";
          },
        },
        {
          title: "乘客姓名",
          props: "passengerName",
        },
        {
          title: "手机号码",
          width: 120,
          props: "passengerPhone",
        },
        {
          title: "身份证号码",
          width: 120,
          props: "idCardNo",
          SpecialJudgment: (res) => {
            return res == "匿名用户"
              ? res
              : res.replace(/^(.{4})(?:\d+)(.{3})$/, "$1******$2");
          },
        },
        {
          title: "是否携童",
          props: "carryingChildren",
          SpecialJudgment: (res) => (res ? "是" : "否"),
        },
        {
          title: "票价",
          props: "ticketPricesPer",
        },
        {
          title: "服务费",
          props: "servicePricesPer",
        },
        {
          title: "应付金额",
          props: "payablePrices",
        },
        {
          title: "优惠金额",
          props: "preferentialPrices",
        },
        {
          title: "实付金额",
          props: "totalPrices",
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            return this.childOrderStatusList.find((t) => t.value == res).label;
          },
        },
        {
          title: "退(改)优惠金额",
          props: "refundTicketPreferentialPrices",
          width: 130,
        },
        {
          title: "退票金额",
          props: "refundTicketPrices",
        },
        {
          title: "退票手续费",
          props: "refundTicketServicePrices",
          width: 120,
        },
        {
          title: "退票时间",
          props: "refundTime",
        },
        {
          title: "改签退回金额",
          props: "changedRefundPrices",
          width: 120,
        },
        {
          title: "改签手续费",
          props: "changedServicePrices",
          width: 120,
        },
        {
          title: "改签时间",
          props: "changedTime",
        },
      ],
      dataPopup: [],
      searchLoading: false,
      tablePopupShow: false,
      //优惠券
      couponsPopupShow: false,
      couponsDataPopup: null,
      scopeList: [
        { value: "GLOBAL", label: "全场代金券" },
        { value: "SINGLE", label: "单品优惠" },
      ],
      couponsList: [
        { value: "CASH", label: "充值型代金券" },
        { value: "NOCASH", label: "免充值型代金券" },
      ],
      //优惠券
    };
  },

  methods: {
    exportFun() {
      let obj = this.deepClone(this.form);
      historyExportAPI(obj).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName =
          "城际专车售票订单 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");
        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.ticketOrder.clientHeight;
      const buttonsHeight = this.$refs.functionButtons.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        36;
    },
    // 点击查询
    onSearch() {
      this.searchLoading = true;
      this.form.currentPage = 1;
      this.getDataList();
    },

    // 点击查看按钮
    openPopupFun(item) {
      this.dataPopup = item;
      referOrderDetialAPI(item.orderId).then((res) => {
        if (res.code === "SUCCESS") {
          this.tablePopupShow = true;
          this.tableData2 = res.data;
        }
      });
    },
    closeFun() {
      this.tablePopupShow = false;
    },
    //优惠券信息
    showCouponsFun(item) {
      referOrderCouponsDetailAPI({ orderId: item.orderId }).then((res) => {
        if (res.code === "SUCCESS") {
          if (res.data.couponId) {
            this.couponsPopupShow = true;
            this.couponsDataPopup = res.data;
          } else {
            this.$message.error("无优惠券使用信息！");
          }
        }
      });
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page;
      this.getDataList();
    },

    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size;
      this.form.currentPage = 1;
      this.getDataList();
      this.computeHeight();
    },

    // 获取订单列表
    getDataList() {
      let obj = this.deepClone(this.form);
      queryScheduleHistoryList(obj).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
          this.tableData = [];
        }
        this.searchLoading = false;
      });
    },

    // 获取线路
    getLineList() {
      getAllLine().then((res) => {
        if (res.code === "SUCCESS") {
          this.lineList = res.data;
        }
      });
    },
    // 获取上一个月时间,返回yyyy-MM-dd字符串
    getLastMonthTime(date) {
      //  1    2    3    4    5    6    7    8    9   10    11   12月
      var daysInMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      var strYear = date.getFullYear();
      var strDay = date.getDate();
      var strMonth = date.getMonth() + 1;
      //一、解决闰年平年的二月份天数   //平年28天、闰年29天//能被4整除且不能被100整除的为闰年,或能被100整除且能被400整除
      if ((strYear % 4 === 0 && strYear % 100 !== 0) || strYear % 400 === 0) {
        daysInMonth[2] = 29;
      }
      if (strMonth - 1 === 0) {
        //二、解决跨年问题
        strYear -= 1;
        strMonth = 12;
      } else {
        strMonth -= 1;
      }
      //  strDay = daysInMonth[strMonth] >= strDay ? strDay : daysInMonth[strMonth];
      strDay = Math.min(strDay, daysInMonth[strMonth]); //三、前一个月日期不一定和今天同一号，例如3.31的前一个月日期是2.28；9.30前一个月日期是8.30

      if (strMonth < 10) {
        //给个位数的月、日补零
        strMonth = "0" + strMonth;
      }
      if (strDay < 10) {
        strDay = "0" + strDay;
      }
      var datastr = strYear + "-" + strMonth + "-" + strDay;
      return datastr;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
    this.form.startDate = this.getLastMonthTime(new Date()) + " 00:00:00";
    let day = new Date();
    this.form.endDate =
      day.getFullYear() +
      "-" +
      (day.getMonth() + 1) +
      "-" +
      day.getDate() +
      " 23:59:59";
    this.onSearch();
    this.getLineList();
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.Table {
  margin-top: 16px;
}
.ticket-order {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 4px 20px;
  box-sizing: border-box;
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}

.theme-project-gps {
  .ticket-order {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
