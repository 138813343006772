var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "couponsDetail", staticClass: "coupons-detail" }, [
    _c("div", { ref: "formHeight", staticClass: "content" }, [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "text" }, [_vm._v("优惠券信息")]),
        _c("div", {
          staticClass: "close",
          on: {
            click: function ($event) {
              return _vm.closeFun()
            },
          },
        }),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "individual" }, [
          _c("div", { staticClass: "label" }, [_vm._v("订单编号")]),
          _c("div", { staticClass: "row-val" }, [
            _vm._v(_vm._s(_vm.dataPopup.orderId)),
          ]),
        ]),
        _c("div", { staticClass: "individual" }, [
          _c("div", { staticClass: "label" }, [_vm._v("线路")]),
          _c("div", { staticClass: "row-val" }, [
            _vm._v(" " + _vm._s(_vm.dataPopup.lineName) + " "),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "individual" }, [
          _c("div", { staticClass: "label" }, [_vm._v("券ID")]),
          _c("div", { staticClass: "row-val" }, [
            _vm._v(_vm._s(_vm.dataPopup.couponId)),
          ]),
        ]),
        _c("div", { staticClass: "individual" }, [
          _c("div", { staticClass: "label" }, [_vm._v("优惠名称")]),
          _c("div", { staticClass: "row-val" }, [
            _vm._v(_vm._s(_vm.dataPopup.name)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "individual" }, [
          _c("div", { staticClass: "label" }, [_vm._v("优惠范围")]),
          _c("div", { staticClass: "row-val" }, [
            _vm._v(_vm._s(_vm.scopeJudgment)),
          ]),
        ]),
        _c("div", { staticClass: "individual" }, [
          _c("div", { staticClass: "label" }, [_vm._v("优惠类型")]),
          _c("div", { staticClass: "row-val" }, [
            _vm._v(_vm._s(_vm.couponsJudgment)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "individual" }, [
          _c("div", { staticClass: "label" }, [_vm._v("优惠券面额")]),
          _c("div", { staticClass: "row-val" }, [
            _vm._v(_vm._s(_vm.dataPopup.amount || 0)),
          ]),
        ]),
        _c("div", { staticClass: "individual" }, [
          _c("div", { staticClass: "label" }, [_vm._v("微信出资")]),
          _c("div", { staticClass: "row-val" }, [
            _vm._v(_vm._s(_vm.dataPopup.wechatpayContribute || 0)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "individual" }, [
          _c("div", { staticClass: "label" }, [_vm._v("商户出资")]),
          _c("div", { staticClass: "row-val" }, [
            _vm._v(_vm._s(_vm.dataPopup.merchantContribute || 0)),
          ]),
        ]),
        _c("div", { staticClass: "individual" }, [
          _c("div", { staticClass: "label" }, [_vm._v("其他出资")]),
          _c("div", { staticClass: "row-val" }, [
            _vm._v(_vm._s(_vm.dataPopup.otherContribute || 0)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "individual" }, [
          _c("div", { staticClass: "label" }, [_vm._v("优惠券使用时间")]),
          _c("div", { staticClass: "row-val" }, [
            _vm._v(_vm._s(_vm.dataPopup.createTime)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "footer" }, [
        _c(
          "div",
          [_c("el-button", { on: { click: _vm.closeFun } }, [_vm._v("取消")])],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }