<template>
<!-- 优惠券详情 -->
  <div ref="couponsDetail" class="coupons-detail">
    <div ref="formHeight" class="content">
      <div class="header">
        <div class="text">优惠券信息</div>
        <div class="close" @click="closeFun()"></div>
      </div>
      <div class="row">
        <div class="individual">
          <div class="label">订单编号</div>
          <div class="row-val">{{ dataPopup.orderId }}</div>
        </div>
        <div class="individual">
          <div class="label">线路</div>
          <div class="row-val">
            {{ dataPopup.lineName }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="individual">
          <div class="label">券ID</div>
          <div class="row-val">{{ dataPopup.couponId }}</div>
        </div>
        <div class="individual">
          <div class="label">优惠名称</div>
          <div class="row-val">{{ dataPopup.name }}</div>
        </div>
      </div>
      <div class="row">
        <div class="individual">
          <div class="label">优惠范围</div>
          <div class="row-val">{{ scopeJudgment }}</div>
        </div>
        <div class="individual">
          <div class="label">优惠类型</div>
          <div class="row-val">{{ couponsJudgment }}</div>
        </div>
      </div>
      <div class="row">
        <div class="individual">
          <div class="label">优惠券面额</div>
          <div class="row-val">{{ dataPopup.amount || 0 }}</div>
        </div>
        <div class="individual">
          <div class="label">微信出资</div>
          <div class="row-val">{{ dataPopup.wechatpayContribute || 0 }}</div>
        </div>
      </div>
      <div class="row">
        <div class="individual">
          <div class="label">商户出资</div>
          <div class="row-val">{{ dataPopup.merchantContribute || 0 }}</div>
        </div>
        <div class="individual">
          <div class="label">其他出资</div>
          <div class="row-val">{{ dataPopup.otherContribute || 0 }}</div>
        </div>
      </div>
      <div class="row">
        <div class="individual">
          <div class="label">优惠券使用时间</div>
          <div class="row-val">{{ dataPopup.createTime }}</div>
        </div>
      </div>
      
      <div class="footer">
        <div>
          <el-button @click="closeFun">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataPopup: Object,
    couponsList: Array,//优惠类型
    scopeList:Array,//优惠范围
  },
  data() {
    return {
    };
  },
  methods: {
    closeFun() {
      this.$emit("closeCouponsFun");
    },
  },
  mounted() { },
  computed: {
     scopeJudgment() {
      return this.scopeList.find((t) => {
        return t.value == this.dataPopup.scope;
      }).label;
    },
    couponsJudgment(){
      return this.couponsList.find(t=> t.value == this.dataPopup.type).label;
    }
  },
};
</script>

<style lang='scss' scoped>
.coupons-detail {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: rgba(63, 63, 63, 0.5);
  top: 0;
  left: 0;
  .content {
    position: absolute;
    z-index: 101;
    background: #ffffff;
    border-radius: 10px;
    width: 1000px;
    padding: 0 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 1px;
    .row {
      width: 100%;
      margin: 10px 0;
      display: flex;
      flex-wrap: wrap;
      .individual {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 43%;
        height: 100%;
        padding: 10px 0;
        .label {
          width: 120px;
          padding-right: 10px;
          text-align: right;
          display: inline-block;
        }
        .row-val {
          padding: 0 10px;
          height: 34px;
          line-height: 34px;
          display: inline-block;
          width: calc(100% - 120px);
          border: 1px solid #d3d3d3;
          border-radius: 4px;
          color: #333333;
          overflow: hidden;
        }
      }
    }
    .header {
      padding: 18px 24px;
      height: 24px;
      width: calc(100% - 48px);
      border-bottom: 1px solid #d7d7d7;
      .text {
        margin-top: 8px;
        display: inline-block;
        font-size: 14px;
      }
      .close {
        float: right;
        margin-top: 10px;
        cursor: pointer;
        width: 12px;
        height: 12px;
        background: url("~@/assets/images/home/close.png") no-repeat;
        background-size: 100%;
        transform: translateY(-50%);
      }
    }
    .footer {
      padding: 10px 0px 18px 24px;
      height: 32px;
      width: calc(100% - 24px);
      border-bottom: none !important;
      border-top: 1px solid #d7d7d7;
      .el-button {
        float: right;
        margin-left: 14px;
      }
      .el-button:first-child {
        background: #336fee;
        color: #ffffff;
      }
    }
  }
}
</style>